export const PROJECT_NAME = "SAMM";
export const PUBLIC_URL="https://grpfinexe.liberty.co.za/";
export const METHOD_ARN="arn:aws:execute-api:eu-west-1:030280086356:633d86eze0";
export const API_URL={host:"633d86eze0.execute-api.eu-west-1.amazonaws.com", url:"https://633d86eze0.execute-api.eu-west-1.amazonaws.com"};
export const DQI_API={url:"https://fx0htjcrcg.execute-api.eu-west-1.amazonaws.com/PROD"};
export const ACCESS_CONTROL_API_URL={host:"https://1cfmbxvji9.execute-api.eu-west-1.amazonaws.com/PROD", url:"https://1cfmbxvji9.execute-api.eu-west-1.amazonaws.com/PROD"};
export const LOOPBACK_API_URL={host:"8j6lks69pd.execute-api.eu-west-1.amazonaws.com", url:"https://8j6lks69pd.execute-api.eu-west-1.amazonaws.com/dev/"};
export const ACCMAN_API_URL={url:"https://y0crtsc680.execute-api.eu-west-1.amazonaws.com/PROD"};
export const DL_API={url: "https://yjlx1lmxzh.execute-api.eu-west-1.amazonaws.com/PROD"}
export const DVD_API={url:"https://7pcoryntzk.execute-api.eu-west-1.amazonaws.com/dev"};
export const DQD_API={url:"https://spjs3foxy8.execute-api.eu-west-1.amazonaws.com/PROD"};
export const FPSL_BT_MAPPING_API="https://bm434g7cpc.execute-api.eu-west-1.amazonaws.com/PROD";
export const MT_API_URL ="https://uk1c47xl0a.execute-api.eu-west-1.amazonaws.com/PROD";
export const REF_DATA_API = "https://mdipxuz1j8.execute-api.eu-west-1.amazonaws.com/DEV";
export const IMPORT_DATA_API = "https://jlc04y97ic.execute-api.eu-west-1.amazonaws.com/STAGING";
export const REPORTABLE_MAPPINGS="https://plh8obqeyd.execute-api.eu-west-1.amazonaws.com/DEV"
export const FN_REPORT = "https://owhj8ykvg4.execute-api.eu-west-1.amazonaws.com/DEV";
export const SHARED_API ="https://ejr8eqs0f6.execute-api.eu-west-1.amazonaws.com/DEV";
